import React, { useEffect } from 'react'
import { Head } from '~/components'
import { Link, PageProps } from 'gatsby'
import { useContext } from '~/hooks'
import * as st from '~/assets/styl/PageDefault.module.styl'
import img from '~/assets/img/img-404.png'
import logoUnicred from '~/assets/svg/svg-logo-dark.svg'
import logoUnidos from '~/assets/svg/svg-logo-unidos-dark.svg'
import cn from 'classnames'

const NotFound = ({ location }: PageProps) => {
  const { setLoading } = useContext()
  useEffect(() => {
    setLoading(true)
    setTimeout(() => setLoading(false), 2000)
  }, [])
  return (
    <>
      <Head location={location} />
      <main className={cn(st.core, st.notFound)}>
        <section className={cn(st.imgText, st.purple)}>
          <img src={img} alt="" />
          <div>
            <h1>Erro 404</h1>
            <p>
              <strong>Página não encontrada.</strong>
            </p>
            <p>
              A página que você tentou acessar não existe ou está fora do ar
              temporariamente.
            </p>
            <p>Confira o endereço e tente novamente.</p>
            <Link to="/">Ir para Home</Link>
          </div>
        </section>
        <div>
          <p>Plano de cargos, carreira e remuneração</p>
          <div>
            <img src={logoUnicred} alt="" />
            <img src={logoUnidos} alt="" />
          </div>
        </div>
      </main>
    </>
  )
}

export default NotFound
